/*fonts*/

@font-face {
  font-family: Everett-Light;
  font-style: normal;
	font-weight: 300;
  src: local("Everett Light"), local("Everett Light"),
  url("/src/fonts/Everett-Light-web.woff2") format("woff2"),
  url("/src/fonts/Everett-Light-web.woff") format("woff");
}

@font-face {
  font-family: Everett-Regular;
  font-style: normal;
	font-weight: 400;
  src: local("Everett Regular"), local("Everett Regular"),
  url("/src/fonts/Everett-Regular-web.woff2") format("woff2"),
  url("/src/fonts/Everett-Regular-web.woff") format("woff");
}

@font-face {
  font-family: Everett-Medium;
  font-style: normal;
	font-weight: 500;
  src: local("Everett Medium"), local("Everett Medium"),
  url("/src/fonts/Everett-Medium-web.woff2") format("woff2"),
  url("/src/fonts/Everett-Medium-web.woff") format("woff");
}

/*variables*/
#root {
  --box-shadow: 1px 1px 3px gray;
  --scanary-icon-size: 56px;
  --quick-access-icon-size: 48px;
  --quick-access-icon-button: 28px;
  --border-radius: 32px;
  --box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.03);

  --color-app-background: #F8F8F8;
  --color-manual-input-background: #ECECEC;
  --color-placeholder: #B7B7B7;
  --color-purple: #7140EB;
  --color-lavender: #E2DCF0;
  --color-rose: #F2DDD9;
  --color-white: #FFFFFF;
  --color-off-white: #F6F6F6;
  --color-black: #000000;
  --color-gray-light: #6D6B72;
  --color-gray-medium: #49464F;
}

/*shared*/

html, body, #root {
  height: 100%;
}

body {
  background-color: var(--color-black);
}

h1, h2, p {
  margin: 0;
}

button {
  color: white;
  background-color: var(--color-purple);
  border: none;
  font-family: Everett;
  cursor: pointer;
}

/*layout*/

.locationButton {
  height: 40px;
  border-radius: 20px;
  padding: 0 20px;
  font-size: 16px;
}

.app {
  background: var(--color-app-background) url("cam.svg") no-repeat center;
  position: relative;
  height: -webkit-fill-available;
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  overflow: hidden;
}

/* When camera permissions are denied */
.scanner-placeholder {
  height: 100vh;
}

.scanner {
  height: 100%;
  display: flex;
  justify-content: center;
}

.scanner .scannerVideoContainer {
  align-self: center;
}

.scanner video {
  border-radius: var(--border-radius);
}

.scanary-wrapper {
  z-index: 999;
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 6px;
}

.scanary-text {
  font-family: 'Everett-Regular';
  font-size: 14px;
  line-height: 120%;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(7.5px);
  border-radius: 30px;
  display: flex;
  max-width: 240px;
  padding: 14px 0;
  box-shadow: var(--box-shadow);
}

.scanary-text p {
  align-self: center;
  color: var(--color-gray-medium);
  margin-left: 20px;
  margin-right: 20px;
}

.scanary-wrapper .scanary {
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(24px);
  border-radius: 50%;
  line-height: var(--scanary-icon-size);
  height: var(--scanary-icon-size);
  width: var(--scanary-icon-size);
  box-shadow: var(--box-shadow);
  font-size: calc(var(--scanary-icon-size) / 2);
  display: flex;
  justify-content: center;
}

@keyframes wiggle {
  30% { transform: scale(1.1); }
  40%, 60% { transform: rotate(-20deg) scale(1.1); }
  50% { transform: rotate(20deg) scale(1.1); }
  70% { transform: rotate(0deg) scale(1.1); }
  100% { transform: scale(1); }
}

.scanary.animate {
  animation: wiggle 0.6s cubic-bezier(0.42, 0, 1, 1) infinite;
}

.getQuickAccess {
  box-shadow: var(--box-shadow);
  border-radius: 24px;
  position: absolute;
  bottom: 0;
  margin: 16px;
  padding: 16px 16px 24px 16px;
  display: flex;
  gap: 12px;
  background: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(24px);
}

.getQuickAccess .icon {
  background-image: url("scanaris_app_icon.png");
  background-size: 100%;

  border-radius: 15px;
  height: var(--quick-access-icon-size);
  min-width: var(--quick-access-icon-size);
}

.getQuickAccess .copy {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.getQuickAccess .title {
  font-family: 'Everett-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #49464F;
}

.getQuickAccess .text {
  font-family: 'Everett-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 135%;
  color: var(--color-gray-light);
}

.getQuickAccess .text span {
  font-family: 'Everett-Medium';
  color: var(--color-gray-medium);
}

.getQuickAccess .button button {
  border-radius: 50%;
  align-self: flex-end;
  line-height: var(--border-radius);
  min-width: var(--quick-access-icon-button);
  height: var(--quick-access-icon-button);
  text-align: center;
  background: var(--color-white) url("close.svg") no-repeat center;
}

.manualInput {
  z-index: 999;
  width: 100%;
  position: absolute;
  background-color: var(--color-white);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  height: 120px;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.manualInput input {
  width: calc(100% - 60px);
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
  border: none;
  font-size: 16px;
  background-color: var(--color-off-white);
  margin-top: 20px;
  padding-left: 20px;
  color: var(--color-gray-medium);
}

.manualInput input::placeholder {
  color: var(--color-placeholder);
  font-family: 'Everett-Regular';
  font-size: 16px;
}

.manualInput input:focus {
  outline: none !important;
  background-color: #var(--color-manual-input-background);
}

.manualInput p {
  font-family: 'Everett-Regular';
  font-size: 12px;
  color: var(--color-gray-light);
  margin-left: 38px;
  margin-right: 20px;
  margin-top: 8px;
}

.manualInput p span {
  font-family: "Everett-Medium";
}